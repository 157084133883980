import styled from '@emotion/styled'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'
import TextareaAutosize from 'react-textarea-autosize'

export interface Props {
  name: string
  placeholder?: string
  required?: boolean
  value?: string
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLTextAreaElement>
}

export const Textarea = memo(
  forwardRef<HTMLTextAreaElement, Props>(function Textarea(
    { name, placeholder, required, value, onBlur, onChange, onFocus },
    ref,
  ) {
    return (
      <Container>
        <TextareaAutosize
          ref={ref}
          name={name}
          placeholder={placeholder}
          required={required}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
      </Container>
    )
  }),
)
Textarea.displayName = 'Textarea'

const Container = styled.div`
  textarea {
    width: 100%;
    height: auto;
    min-height: 2.25rem;
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 0.875rem;
    line-height: 1.625rem;
    color: ${({ theme }) => theme.colors.variants.neutralDark4};
    border-bottom: 2px solid
      ${({ theme }) => theme.colors.variants.neutralLight1};
    padding: 0.375rem 0;
    resize: none;
  }
`
