import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { memo, useEffect, useState } from 'react'
import * as Icon from 'react-feather'

export interface Props {
  text: string
  title: string
  type?: 'confirm' | 'error'
}

export const FormMessages = memo(function FormMessages({
  text,
  title,
  type = 'confirm',
}: Props) {
  const [openMessageModal, setMessageModalOpen] = useState(false)
  const [buttonCloseVisible, setButtonClose] = useState(true)

  useEffect(() => {
    setButtonClose(true)

    if (buttonCloseVisible) {
      setMessageModalOpen(true)
    }
  }, [buttonCloseVisible])

  return (
    <Container
      className={`confirmation-message${openMessageModal ? ' opened' : ''}`}
    >
      <Wrapper>
        <Close
          onClick={() => {
            setMessageModalOpen(false)
          }}
        />
        <TextWrap>
          <SVG dial={5}>
            {type === 'error' ? <Icon.Slash /> : <Icon.Check />}
          </SVG>
          <Title>{title}</Title>
          <Text dangerouslySetInnerHTML={{ __html: text }} />
        </TextWrap>
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.3s;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.opened {
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 34.875rem;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
`

const TextWrap = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 4.5rem 4.5rem 7.5rem;

  @media (max-width: 767px) {
    padding: 3rem 3rem 3.75rem;
  }
`

const SVG = styled(FlexBox)`
  width: 48px;
  height: 48px;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  margin: 0 auto 2.25rem;
  svg {
    width: auto;
    height: 24px;
    stroke: ${({ theme }) => theme.colors.variants.primaryDark1};
    stroke-width: 1.5;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.9375rem;
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
  margin-top: 0.75rem;
`

const Close = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: calc(-40px - 1.5rem);
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  &:before,
  &:after {
    content: '';
    width: 2px;
    height: 20px;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 8px;
    left: 18px;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`
