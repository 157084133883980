import styled from '@emotion/styled'
import { theme } from 'app/theme'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

var QRCode = require('qrcode.react')

export interface Props {
  file?: string
  title?: string
  URL?: string
}

export const QRCodeGenerator = memo(function QRCodeGenerator({
  file,
  title,
  URL,
}: Props) {
  return (
    <Container>
      {title ? (
        <Fade>
          <Title>{title}</Title>
        </Fade>
      ) : null}
      {file ? (
        <Code
          fgColor={theme.colors.variants.neutralDark4}
          level="Q"
          value={file}
        />
      ) : null}
      {URL ? (
        <Code
          fgColor={theme.colors.variants.neutralDark4}
          level="Q"
          value={URL}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 9.75rem 0;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 7.5rem 0;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2.9375rem;
  margin-bottom: 1.125rem;
`

const Code = styled(QRCode)`
  margin: 1.5rem;
`
